@font-face {
    font-family: "Facit";
    src: url("Facit-Regular.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "FacitBold";
    src: url("Facit-Bold.otf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

